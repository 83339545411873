import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import FolderView from "../molecules/FolderView";
import {GatsbyImage} from "gatsby-plugin-image";
import MarkdownConverter from "../atoms/MarkdownConverter";
import {scroller} from 'react-scroll';
import EmbedContainer from "../atoms/EmbedContainer";

const Holder = styled.div`
  display: flex;
  width: 100%;
  overflow: scroll;
`;

const Content = styled.div`
  border-left: 1px dotted;
  padding: 0.5rem;
  min-height: calc(100vh - 3rem);
  flex-shrink: 0;
  width: 90vw;
  min-width: 14rem;
  list-style: none;
  margin: 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    width: 70vw;
  }

  &:first-child {
    border-left: none;
  }

  .text-holder,
  .embed-holder {
    .inner {
      max-width: 50rem;
    }
  }
  .text-holder {
    .inner {
      div {
        > :first-child { margin-top: 0; }
        > :last-child { margin-bottom: 0; }
      }
      p {
        margin: 1rem 0;
      }
    }
  }
`;

function FoldersHolder({folders}) {
  const [openFolders, setOpenFolders] = useState([]);
  const [openImage, setOpenImage] = useState(null);
  const [openText, setOpenText] = useState(null);
  const [openEmbed, setOpenEmbed] = useState(null);
  const holder = useRef(null);

  useEffect(() => {
    const firstFolder = folders.find(folder => folder.data.First_folder);
    if (firstFolder) {
      setOpenFolders([firstFolder.id]);
    }
  }, [folders]);

  useEffect(() => {
    if (holder.current) {
      scroller.scrollTo('endItem', {
        containerId: 'folders-holder',
        horizontal: true,
        smooth: true,
        duration: 250,
      });
    }
  }, [openFolders, openImage, openEmbed, openText]);

  const closeOpenContent = () => {
    if (openImage ) setOpenImage(null);
    if (openText ) setOpenText(null);
    if (openEmbed ) setOpenEmbed(null);
  };

  const handleFolderClick = (folderId, parentId) => {
    // Close any open content
    closeOpenContent();
    // Find parent (containing folder) of the clicked folder
    const parentIndex = openFolders.findIndex(folder => folder === parentId);
    // Set new open folders temporarily to current open folders
    let newOpenFolders = [...openFolders];
    // Trim the open folders array to the parent of the clicked folder
    newOpenFolders.length = parentIndex + 1;
    // Add the clicked folder to the array so that it will display at the end
    newOpenFolders.push(folderId);
    // Set these open folders
    setOpenFolders(newOpenFolders);
  }

  const handleContentClick = (parentId) => {
    // Close any open content
    closeOpenContent();
    // Find parent (containing folder) of the clicked piece of content
    const parentIndex = openFolders.findIndex(folder => folder === parentId);
    // Set new open folders temporarily to current open folders
    let newOpenFolders = [...openFolders];
    // Trim the open folders array to the parent of the clicked folder (this will close all trailing folders)
    newOpenFolders.length = parentIndex + 1;
    // Set these open folders
    setOpenFolders(newOpenFolders);
  }


  if (openFolders.length < 1) return null;
  return (
    <>
      <Holder ref={holder} id="folders-holder">
        {openFolders.map((folderId, i) => {
          let folderObject = folders.find(folder => folder.id === folderId);
          if (folderObject) {
            return (
              <FolderView
                name={(
                  !openImage && !openText && !openEmbed &&
                  i === openFolders.length - 1
                ) ? 'endItem' : ''}
                key={folderId}
                folder={folderObject}
                selectedFolder={openFolders[i + 1]}
                folderClickHandler={(folderId, parentId) => handleFolderClick(folderId, parentId)}
                imageOpenHandler={(imageObject) => {
                  handleContentClick(folderId);
                  setOpenImage(imageObject);
                }}
                textOpenHandler={(textObject) => {
                  handleContentClick(folderId);
                  setOpenText(textObject)
                }}
                embedOpenHandler={(embedObject) => {
                  handleContentClick(folderId);
                  setOpenEmbed(embedObject)
                }} />
            )
          } else {
            return null;
          }
        })}

        {openImage && <Content className="endItem">
          <GatsbyImage
            alt={openImage.data.Title}
            objectFit="contain"
            objectPosition="top left"
            image={openImage.data.Image.localFiles[0].childImageSharp.gatsbyImageData} />
        </Content>}

        {openText && <Content className="endItem">
          <div className="text-holder">
            <div className="inner">
              <MarkdownConverter content={openText.data.Content} />
            </div>
          </div>
        </Content>}
        {openEmbed && <Content className="endItem">
          <div className="embed-holder">
            <div className="inner">
              {openEmbed.data.Icon === 'Video' &&
              <EmbedContainer><MarkdownConverter content={openEmbed.data.Embed_code} /></EmbedContainer>}
              {openEmbed.data.Icon === 'Audio' && <MarkdownConverter content={openEmbed.data.Embed_code} />}
            </div>
          </div>
        </Content>}

      </Holder>
    </>
  )
}

FoldersHolder.propTypes = {
  folders: PropTypes.array.isRequired,
};

export default FoldersHolder;