import React from "react";
import Seo from "../components/molecules/Seo";
import styled from "styled-components";
import {graphql} from 'gatsby';
import FoldersHolder from "../components/organisms/FoldersHolder";

const Holder = styled.div`
`;

function IndexPage({data}) {

  return (
    <Holder>
      <Seo title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <FoldersHolder folders={data.allAirtableFolders.nodes}/>
    </Holder>
  )
}

export default IndexPage;

export const homepageQuery = graphql`
query HomeQuery {
  allAirtableFolders {
    nodes {
      id
      data {
        First_folder
        Title
        Display_name
        Sub_folders {
          id
          data {
            Title
            Display_name
            First_folder
          }
        }
        Images {
          id
          data {
            Title
            Image {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
        Texts {
          id
          data {
            Title
            Content
          }
        }
        Embeds {
          id
          data {
            Title
            Embed_code
            Icon
          }
        }
      }
    }
  }
}
`;
