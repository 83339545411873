import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import OpenFolder from '../../assets/svg/folder-open.inline.svg';
import ClosedFolder from '../../assets/svg/folder-closed.inline.svg';
import About from '../../assets/svg/about.inline.svg';
import AudioIcon from '../../assets/svg/audio.inline.svg';
import VideoIcon from '../../assets/svg/video.inline.svg';
import {GatsbyImage} from "gatsby-plugin-image";

const Holder = styled.ul`
  border-left: 1px dotted;
  padding: 0.5rem;
  min-height: calc(100vh - 3rem);
  flex-shrink: 0;
  width: 20vw;
  min-width: 14rem;
  list-style: none;
  margin: 0;

  &:first-child {
    border-left: none;
  }
`
const Item = styled.li`
  button {
    display: flex;
    align-items: center;
    line-height: 1.1;
    padding: 0.2rem 0;
    text-align: left;

    span {
      white-space: normal;
    }

    svg {
      height: auto;
      width: 1.2rem;
      margin: 0 0.5rem 0 0;
      flex-shrink: 0;

      path, rect {
        stroke: ${(props) => props.theme.primary};
      }
    }

    .gatsby-image-wrapper {
      height: 1.2rem;
      width: 1.2rem;
      margin-right: 0.5rem;
    }
  }
`;


function FolderView({folder, folderClickHandler, imageOpenHandler, textOpenHandler, embedOpenHandler, selectedFolder, name}) {
  return (
    <Holder name={name}>
      {folder.data.Sub_folders && folder.data.Sub_folders.map(subFolder =>
        <Item key={subFolder.id}>
          <button className="link" onClick={() => folderClickHandler(subFolder.id, folder.id)}>
            {selectedFolder === subFolder.id && <OpenFolder />}
            {selectedFolder !== subFolder.id && <ClosedFolder />}
            <span>{subFolder.data.Display_name || subFolder.data.Title}</span></button>
        </Item>
      )}
      {folder.data.Texts && folder.data.Texts.map(text =>
        <Item key={text.id}>
          <button className="link" onClick={() => textOpenHandler(text)}><About /><span>{text.data.Title}</span>
          </button>
        </Item>
      )}
      {folder.data.Images && folder.data.Images.map(image =>
          image.data.Image && <Item key={image.id}>
            <button className="link" onClick={() => imageOpenHandler(image)}>
              <GatsbyImage
                alt={image.data.Title}
                objectFit="contain"
                sizes="100px"
                image={image.data.Image.localFiles[0].childImageSharp.gatsbyImageData} /><span>{image.data.Title}</span>
            </button>
          </Item>
      )}
      {folder.data.Embeds && folder.data.Embeds.map(embed =>
        <Item key={embed.id}>
          <button className="link" onClick={() => embedOpenHandler(embed)}>{embed.data.Icon === 'Video' ?<VideoIcon /> :<AudioIcon/>}<span>{embed.data.Title}</span>
          </button>
        </Item>
      )}
    </Holder>
  )
}

FolderView.propTypes = {
  folder: PropTypes.object.isRequired,
  selectedFolder: PropTypes.string,
  name: PropTypes.string,
  folderClickHandler: PropTypes.func.isRequired,
  imageOpenHandler: PropTypes.func.isRequired,
  textOpenHandler: PropTypes.func.isRequired,
  embedOpenHandler: PropTypes.func.isRequired,
};

export default FolderView;